import Link from 'next/link'
import {ctaEvent} from "../../../utils/events";

export default function Footer() {

    return (
        <>
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>To Get Started, Simply Click The Button! </h1>                            
                        </div>
                        <div className="col-md-12">
                            <a className="btn btn-default cta-btn" href="/submit-extension" onClick={()=>ctaEvent("btf-cta")} id="btf-cta">Submit Tax Extension</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
